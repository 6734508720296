export const CLASS_ICMS_FIELDS = {
  class_icms_id: "ID",
  class_icms_nome: "Nome",
  class_icms_formatributacao: "Forma Tributação",
  class_icms_desc_fundamento: "Descrição",
  class_icms_fundamento_legal: "Fundamento Legal",
  class_icms_data_fim: "Fim de vigência",
  class_icms_data_ini: "Inicio de vigência",
  class_icms_data_alteracao: "data da alteração",
  class_icms_rbc: "RBC ICMS",
  class_icms_uf: "Estado",
  cclass_icms_rbcst: "RBC ICMS ST",
  class_icms_cst: "CST",
  class_icms_gru_id: "Grupo de portaria",
  class_icms_alq: "Aliquota de ICMS",
  class_icms_alqst: "Aliquota de ICMS ST",
  class_fecp: "FECP",
  class_fecp_st: "FECP ST",
};

export const CLASS_ICMS_STATUS_ENUM = {
  ATUAL: "ATUAL",
  INCORRETO: "INCORRETO",
  VENCIDO: "VENCIDO",
  AGUARDANDO: "AGUARDANDO",
};
