import { useFetchMixAPi1, useMixApi1, useMixApi1Toast } from "hooks";
import { useCallback } from "react";
import { getURLWithParams } from "utils/context";

export const useIcmsList = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/mxf_class_icms", params));

export const useIcmsApproveList = (params = {}) =>
  useFetchMixAPi1(
    getURLWithParams("/legislacao/class_icms_update/pending-list", params),
  );

export const usePortariaList = (params = {}) =>
  useFetchMixAPi1(getURLWithParams("/legislacao/portaria/list", params));

export const useIcmsHistory = id =>
  useFetchMixAPi1(id ? `/legislacao/class_icms_update/history/${id}` : null);

export const useIcmsRelCasosList = id =>
  useFetchMixAPi1(id ? `/legislacao/mxf_class_icms/casos/${id}` : null);

export const useIcmsRelCatList = id =>
  useFetchMixAPi1(id ? `/legislacao/mxf_class_icms/categorias/${id}` : null);

export const useIcmsDetail = id =>
  useFetchMixAPi1(id ? `/legislacao/mxf_class_icms/detalhe/${id}` : null);

export const useIcmsUpdateDetail = id =>
  useFetchMixAPi1(id ? `/legislacao/class_icms_update/detail/${id}` : null);

export const useIcmsAsync = () => {
  const api = useMixApi1();

  const fetch = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("legislacao/grupo-especificacoes/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );
  return {
    fetch,
  };
};

export const useClassIcms = (id = null) => {
  const { onSend } = useMixApi1Toast();

  const onCreate = async data => {
    const endpoint = id
      ? `legislacao/class_icms_update/create`
      : `legislacao/mxf_class_icms/novo`;
    const type = "POST";

    onSend({
      endpoint,
      messageSuccess: id
        ? `Edição solicitada com Sucesso`
        : `Classificação ICMS Cadastrado com Sucesso`,
      messageFail: id
        ? `Não foi possível solicitar edição`
        : `Não foi possível cadastrar a Classificação`,
      data,
      type,
      redirect: true,
      fieldGetId: "class_icms_id",
      urlRedirect: "/legislacao/icms/editar",
    });
  };
  return {
    onCreate,
  };
};
export const useClassIcmsUpdate = () => {
  const { onSend } = useMixApi1Toast();

  const onProcess = async data => {
    const endpoint = `legislacao/class_icms_update/update`;
    const type = "POST";

    onSend({
      endpoint,
      messageSuccess: `Atualização processada!`,
      messageFail: `Não foi possível processar a atualização`,
      data,
      type,
      redirect: false,
    });
  };

  const onUpdateApproved = async data => {
    const endpoint = `legislacao/class_icms_update/approved-update`;
    const type = "PUT";

    onSend({
      endpoint,
      messageSuccess: `Atualização processada!`,
      messageFail: `Não foi possível processar a atualização`,
      data,
      type,
      redirect: false,
    });
  };
  return {
    onProcess,
    onUpdateApproved,
  };
};
