import { createSlice } from "@reduxjs/toolkit";

export const inicialCbenef = {
  params: {
    page: 1,
    limit: 10,
  },
  paramReports: {
    page: 1,
    limit: 10,
  },
  paramReportsCasosRegras: {
    page: 1,
    limit: 10,
  },
};

const initialState = () => {
  return inicialCbenef;
};

export const CbenefSlice = createSlice({
  name: "cbenef",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleParamsReports: (state, { payload }) => {
      const field = payload?.field;
      state.paramReports[field] = payload?.value;
      return state;
    },
    handleParamsReportsCasosRegras: (state, { payload }) => {
      const field = payload?.field;
      state.paramReportsCasosRegras[field] = payload?.value;
      return state;
    },
    handleClearParams: (state, { payload }) => {
      const field = payload?.field;
      delete state.params[field];
      return state;
    },
  },
});

export const {
  handleParams,
  handleClearParams,
  handleParamsReportsCasosRegras,
  handleParamsReports,
} = CbenefSlice.actions;
export default CbenefSlice.reducer;
