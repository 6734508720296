import React from "react";
import { Copy } from "react-feather";
import toast from "react-hot-toast";
import { Badge } from "reactstrap";
import { limitCaracter } from "utils";
import { boolean } from "yup";

const NewBadge = props => {
  const {
    color = "primary",
    value,
    isCopy = false,
    className,
    title,
    valueCopy,
    isLimitCaracter = false,
    limitText = 5,
    ...rest
  } = props;
  const handleCopy = value => {
    if (valueCopy?.length > 0) {
      navigator.clipboard.writeText(valueCopy);
    } else {
      navigator.clipboard.writeText(value);
    }

    return toast.success(`Copiou ${valueCopy?.length > 0 ? valueCopy : value}`);
  };
  return (
    <Badge
      style={{
        cursor: isCopy && "pointer",
        margin: "3px",
      }}
      onClick={() => isCopy && handleCopy(value)}
      color={color}
      className={className}
      {...rest}
    >
      {title || ""}&nbsp;
      {isLimitCaracter ? limitCaracter(limitText, value) : value}&nbsp;
      {isCopy && <Copy size={11} />}
    </Badge>
  );
};

NewBadge.AppProps = {
  color: String,
  value: String || JSX.Element || [],
  isCopy: boolean,
  className: String,
};

export default NewBadge;
