import { createSlice } from "@reduxjs/toolkit";

export const inicialCasos = {
  params: {
    limit: 10,
    page: 1,
  },
  paramsCasos: {
    limit: 10,
    page: 1,
  },
  paramsCategorias: {
    limit: 10,
    page: 1,
  },
  modal: {
    showCaso: false,
    showCategoria: false,
    showApproval: false,
    showPortaria: false,
  },
};

const initialState = () => {
  return inicialCasos;
};

export const CasosSlice = createSlice({
  name: "classificacaoIcms",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleSetSearch: (state, { payload }) => {
      if (payload?.isDelete) {
        delete state.params[payload?.field];
      } else {
        state.params[payload?.field] = payload?.value;
      }
    },
    handleModalCaso: (state, { payload }) => {
      state.modal.showCaso = payload;
      return state;
    },
    handleModalCategoria: (state, { payload }) => {
      state.modal.showCategoria = payload;
      return state;
    },
    handleModalApproval: (state, { payload }) => {
      state.modal.showApproval = payload;
      return state;
    },
    handleModalPortaria: (state, { payload }) => {
      state.modal.showPortaria = payload;
      return state;
    },
  },
});

export const {
  handleParams,
  handleSetSearch,
  handleModalCaso,
  handleModalCategoria,
  handleModalApproval,
  handleModalPortaria,
} = CasosSlice.actions;

export default CasosSlice.reducer;
