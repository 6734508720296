import { createSlice } from "@reduxjs/toolkit";

import { format, parse, subMonths } from "date-fns";
const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

export const initialproductsRelatory = {
  isOpenModalM2: false,
  isOpenModalFormM2: false,
  storeSelectedUpdateM2: {
    _id: null,
  },
  detailProduct: {
    isShow: false,
    pcn_id: "",
    card: {
      selected: "tributario",
    },
  },
  cardAll: {
    dateInit: firstDay,
    dateEnd: currentDay,
  },

  cardModalTotalTickets: {
    dateInit: "",
    dateEnd: currentDay,
  },
  cardSale: {
    dateInit: firstDay,
    dateEnd: currentDay,
    dateInitPast: parse(
      format(subMonths(currentDay, 1), "yyyy-MM-01"),
      "yyyy-MM-dd",
      1,
    ),
    dateEndPast: subMonths(currentDay, 1),
  },
  cardCouponsSold: {
    dateInit: firstDay,
    dateEnd: currentDay,
    dateInitPast: parse(
      format(subMonths(currentDay, 1), "yyyy-MM-01"),
      "yyyy-MM-dd",
      1,
    ),
    dateEndPast: subMonths(currentDay, 1),
  },
  cardSkuMBuy: {
    dateInit: firstDay,
    dateEnd: currentDay,
    dateInitPast: parse(
      format(subMonths(currentDay, 1), "yyyy-MM-01"),
      "yyyy-MM-dd",
      1,
    ),
    dateEndPast: subMonths(currentDay, 1),
  },
  cardSkuMSale: {
    dateInit: firstDay,
    dateEnd: currentDay,
    dateInitPast: parse(
      format(subMonths(currentDay, 1), "yyyy-MM-01"),
      "yyyy-MM-dd",
      1,
    ),
    dateEndPast: subMonths(currentDay, 1),
  },
  calcForm: {
    regimeTributario: "LR",
    operation: "INTERNA",
    supplier: "ei",
    sale_in: "sac",
  },
  cardsItem: {
    type_icms_compra: "EI",
    type_icms_venda: "SNC",
  },
  list: [],
  params: {
    page: 1,
    limit: 100,
  },
  columns: [],
  openSelectColumns: false,
  filtersType: "",
  filters: {
    isFilterEanGtin: false,
    divergencia_pis_cofins: [],
    divergencia_icms_e: [],
    divergencia_icms_s: [],
    carga_trib: [],
  },

  type: "",
  totalPages: 0,
  modalExportOpen: false,
  openExportColumns: false,
  defaultExpanded: false,
  export: {
    fileName: "",
    fileType: "",
    columns: [],
  },
};

const initialState = () => {
  return initialproductsRelatory;
};

const handleCardTotalTicketsReducer = (state, { payload }) => {
  state.cardModalTotalTickets.dateInit = payload.dateInit;
  state.cardModalTotalTickets.dateEnd = payload.dateEnd;
};

const handleCardSaleReducer = (state, { payload }) => {
  state.cardSale.dateInit = payload.dateInit;
  state.cardSale.dateEnd = payload.dateEnd;
  state.cardSale.dateInitPast = subMonths(payload.dateInit, 1);
  state.cardSale.dateEndPast = subMonths(payload.dateEnd, 1);
};

const handleCardCuponsReducer = (state, { payload }) => {
  state.cardCouponsSold.dateInit = payload.dateInit;
  state.cardCouponsSold.dateEnd = payload.dateEnd;
  state.cardCouponsSold.dateInitPast = subMonths(payload.dateInit, 1);
  state.cardCouponsSold.dateEndPast = subMonths(payload.dateEnd, 1);
};

const handleCardSkuMBuyReducer = (state, { payload }) => {
  state.cardSkuMBuy.dateInit = payload.dateInit;
  state.cardSkuMBuy.dateEnd = payload.dateEnd;
  state.cardSkuMBuy.dateInitPast = subMonths(payload.dateInit, 1);
  state.cardSkuMBuy.dateEndPast = subMonths(payload.dateEnd, 1);
};

const handleCardSkuMSaleReducer = (state, { payload }) => {
  state.cardSkuMSale.dateInit = payload.dateInit;
  state.cardSkuMSale.dateEnd = payload.dateEnd;
  state.cardSkuMSale.dateInitPast = subMonths(payload.dateInit, 1);
  state.cardSkuMSale.dateEndPast = subMonths(payload.dateEnd, 1);
};

const handleCardAllDateReducer = (state, payload) => {
  state.cardAll.dateInit = payload?.payload?.dateInit;
  state.cardAll.dateEnd = payload?.payload?.dateEnd;
  handleCardSaleReducer(state, payload);
  handleCardCuponsReducer(state, payload);
  handleCardSkuMBuyReducer(state, payload);
  handleCardSkuMSaleReducer(state, payload);
};

export const productsRelatorySlice = createSlice({
  name: "productsRelatory",
  initialState,
  reducers: {
    handleCardCupons: handleCardCuponsReducer,
    handleCardSale: handleCardSaleReducer,
    handleCardSkuMBuy: handleCardSkuMBuyReducer,
    handleCardSkuMSale: handleCardSkuMSaleReducer,
    handleCardsAll: handleCardAllDateReducer,
    handleCardTotalTickets: handleCardTotalTicketsReducer,
    handleParamsPage: (state, { payload }) => {
      state.params.page = payload.page;
    },
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
      state.totalPages = payload.totalPages;
    },
    handleOpenAllItens: (state, { payload }) => {
      state.defaultExpanded = payload;
    },
    handleSetFilterField: (state, { payload }) => {
      console.log({ payload });
      if (payload?.field === "isFilterEanGtin") {
        state.filters.isFilterEanGtin = payload?.value;
      }
      if (payload?.field === "carga_trib") {
        state.filters.carga_trib = payload?.value;
      }
      if (payload?.field === "divergencia_pis_cofins") {
        state.filters.divergencia_pis_cofins = payload?.value ?? [];
      }
      if (payload?.field === "divergencia_icms_e") {
        state.filters.divergencia_icms_e = payload?.value ?? [];
      }
      if (payload?.field === "divergencia_icms_s") {
        state.filters.divergencia_icms_s = payload?.value ?? [];
      }
    },
    handleRemoveFilterFieldItem: (state, { payload }) => {
      const newList = state.filters?.pis_cofins
        .filter(item => item?.value !== payload?.value?.value)
        .map(item => item);

      state.filters.pis_cofins = newList;
    },
    handleSetColumns: (state, { payload }) => {
      state.columns = payload;
    },
    handleOpenModalExport: (state, { payload }) => {
      state.modalExportOpen = payload;
    },
    handleEnableColumns: (state, { payload }) => {
      const list = [...state.columns];
      list[payload?.key].omit = payload?.omit;
      state.columns = [...list];
    },
    handleSetTypeFilter: (state, { payload }) => {
      state.filtersType = payload?.type;
    },
    handleOpenSelectColumns: (state, { payload }) => {
      state.openSelectColumns = payload;
    },
    handleOpenselectExportColuns: (state, { payload }) => {
      state.openExportColumns = payload;
    },
    handleAddAllExportColumns: (state, { payload }) => {
      const checkExistent = (state.export.columns || []).map(item => item);
      if (checkExistent.length > 0) {
        state.export.columns = [];
      }
      state.export.columns = payload;
    },

    handleAddColumns: (state, { payload }) => {
      const checkExistent = state.export.columns.filter(
        item => item?.sortField === payload?.item?.sortField,
      );

      if (checkExistent?.length > 0) {
        const newList = (state.export.columns || [])
          .filter(item => item?.sortField !== payload?.item?.sortField)
          .map(item => item);

        state.export.columns = [...newList];
      }

      if (!checkExistent?.length) {
        const itemAdd = {
          name: payload?.item?.name,
          sortField: payload?.item?.sortField,
        };

        state.export.columns = [...state.export.columns, itemAdd];
      }
    },
    handleSetExport: (state, { payload }) => {
      if (payload?.name) {
        state.export.fileName = payload?.name;
      }

      if (payload?.type) {
        state.export.fileType = payload?.type;
      }
    },

    // TODO: STATE MODAL
    handleOpenDetailProduct: (state, { payload }) => {
      state.detailProduct.pcn_id = payload?.pcn_id;
      state.detailProduct.show = payload?.show;
    },

    handleSetNewCard: (state, { payload }) => {
      state.detailProduct.card.selected = payload.selected;
    },
    handleSetCalcForm: (state, { payload }) => {
      state.calcForm.regimeTributario = payload?.type;
      state.calcForm.operation = payload?.operation;
      state.calcForm.supplier = payload?.supplier;
      state.calcForm.sale_in = payload?.saleIn;
    },
    handleSetCalcFormSupplier: (state, { payload }) => {
      state.calcForm.supplier = payload?.supplier;
    },
    handleSetCalcFormSaleIn: (state, { payload }) => {
      state.calcForm.sale_in = payload?.saleIn;
    },
    handleSetTypeCards: (state, { payload }) => {
      console.log({ payload });
      if (payload?.type === "compra") {
        state.cardsItem.type_icms_compra = payload?.value;
      }
      if (payload?.type === "venda") {
        state.cardsItem.type_icms_venda = payload?.value;
      }
      if (payload?.type === "addings") {
        state.cardsItem.type_icms_venda_addings = payload?.value;
      }
    },
    handleSetOpenModalM2: (state, { payload }) => {
      state.isOpenModalM2 = payload;
    },
    handleSetOpenModalFormM2: (state, { payload }) => {
      state.isOpenModalFormM2 = payload;
    },
    handleSelectedStoreUpdateM2: (state, { payload }) => {
      state.storeSelectedUpdateM2._id = payload?.id;
    },
  },
});

export const {
  handleParamsPage,
  handleSetList,
  handleRemoveFilterFieldItem,
  handleAddAllExportColumns,
  handleOpenselectExportColuns,
  handleAddColumns,
  handleSetTypeFilter,
  handleSetColumns,
  handleOpenSelectColumns,
  handleEnableColumns,
  handleOpenModalExport,
  handleSetExport,
  handleSetFilterField,
  handleOpenAllItens,

  // TODO:: STATE MODAL
  handleOpenDetailProduct,
  handleSetNewCard,
  handleSetCalcForm,
  handleSetCalcFormSupplier,
  handleSetCalcFormSaleIn,
  handleSetOpenModalM2,
  handleSetOpenModalFormM2,

  // TODO: Cards Modal
  handleSetTypeCards,
  handleCardsAll,
  handleCardSale,
  handleCardCupons,
  handleCardSkuMBuy,
  handleCardSkuMSale,
  handleCardTotalTickets,
  handleSelectedStoreUpdateM2,
} = productsRelatorySlice.actions;

export default productsRelatorySlice.reducer;
