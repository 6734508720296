import { createSlice } from "@reduxjs/toolkit";

export const initialDashLegis = {
  selectedStates: [],
  showList: {
    name: "",
    show: "",
  },
  params: {
    codSpedPisCofins: {
      limit: 10,
      page: 1,
      type: "",
    },
    aliqCbenef: {
      limit: 10,
      page: 1,
      type: "",
      uf: [],
    },
    categoriaSemNota: {
      limit: 10,
      page: 1,
      type: "",
      uf: [],
    },
    faltaEspecificacao: {
      limit: 10,
      page: 1,
      type: "",
      uf: [],
    },
    faltaCbenef: {
      limit: 10,
      page: 1,
      type: "",
      uf: [],
    },
    categoriasSemCest: {
      limit: 10,
      page: 1,
      type: "",
      uf: [],
    },
    categoriasSemRegras: {
      limit: 10,
      page: 1,
      type: "",
      uf: [],
    },
  },
  selectListsType: {},
};

const initialState = () => {
  return initialDashLegis;
};

const handleParamsField = (state, field, payload) => {
  const arrSelected = [...(state?.params[field]?.uf || [])];

  const checkExistsArrParams = arrSelected?.filter(
    item => item === payload?.state?.properties?.id,
  );

  if (!checkExistsArrParams?.length) {
    state.params[field].uf = [
      ...state.params.aliqCbenef.uf,
      payload?.state?.properties?.id,
    ];
  } else {
    state.params[field].uf = state?.params[field]?.uf?.filter(
      item => item !== payload?.state?.properties?.id,
    );
  }
};

const handleUpdateParamsState = (state, payload) => {
  const stateSelected = [...state.selectedStates];

  const checkExists = stateSelected?.filter(
    item => item?.state?.properties?.id === payload?.state?.properties?.id,
  );

  handleParamsField(state, "aliqCbenef", payload);
  handleParamsField(state, "categoriaSemNota", payload);
  handleParamsField(state, "faltaEspecificacao", payload);
  handleParamsField(state, "faltaCbenef", payload);
  handleParamsField(state, "categoriasSemCest", payload);
  handleParamsField(state, "categoriasSemRegras", payload);

  if (checkExists?.length) {
    state.selectedStates = stateSelected?.filter(
      item => item?.state?.properties?.id !== payload?.state?.properties?.id,
    );
  } else {
    const updateSelecteds = stateSelected.map(item => {
      return {
        ...item,
      };
    });
    state.selectedStates = [...updateSelecteds, payload];
  }
};

export const legisDashSlice = createSlice({
  name: "legisDash",
  initialState,
  reducers: {
    handleShowList: (state, { payload }) => {
      state.showList.name = payload?.name;
      state.showList.show = payload?.show;
    },
    handleParams: (state, { payload }) => {
      state.params.type = payload.type;

      const Type = state.params.type;
      const params = state.params.type;

      if (state.params.type === Type) {
        const field = payload?.field;
        state.params[params][field] = payload?.value;
      }
    },
    handleSelectStates: (state, { payload }) => {
      handleUpdateParamsState(state, payload);

      return state;
    },
    handleSelectListsType: (state, { payload }) => {
      state.selectListsType = payload;
    },
  },
});

export const {
  handleSetGeo,
  handleShowList,
  handleParams,
  handleSelectStates,
  handleSelectListsType,
} = legisDashSlice.actions;

export default legisDashSlice.reducer;
