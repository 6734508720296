import React, { useState } from "react";
import { Input, Label, Spinner, Tooltip } from "reactstrap";
import { Controller } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import { useSkin } from "@hooks/useSkin";

import * as S from "./styles";
import { Info } from "react-feather";

export const helperTextError = ({ value, error, helperText }) => (
  <S.InputFieldHelperText error={!value && error}>
    {helperText}
  </S.InputFieldHelperText>
);

export const LabelInput = ({ name, label }) => {
  const [isShowPopover, setIsShowPopover] = useState("");
  const PopoverId = `Popover_${name}`;

  const OpenPopover = () => {
    isShowPopover === PopoverId
      ? setIsShowPopover("")
      : setIsShowPopover(PopoverId);
  };

  return (
    <>
      <Label
        for={name}
        style={{
          cursor: "pointer",
        }}
        onMouseEnter={() => OpenPopover()}
        onMouseLeave={() => setIsShowPopover("")}
        className="form-label mx-1"
        id={PopoverId}
      >
        {label} <Info size={10} />
      </Label>
      {PopoverId && (
        <Tooltip
          isOpen={Boolean(isShowPopover)}
          placement="right"
          target={PopoverId}
        >
          O campo {label} e obrigátorio
        </Tooltip>
      )}
    </>
  );
};

export const TextField = ({
  name,
  control,
  label,
  helperText,
  error,
  width,
  isDisabledPopover = false,
  isLoading,
  required = false,
  ...props
}) => {
  if (control) {
    return (
      <Controller
        rules={{ required }}
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <S.InputFieldContainer>
            {required && !isDisabledPopover ? (
              <LabelInput name={name} label={label} />
            ) : (
              <Label
                for={props?.name}
                className="mx-1"
                hidden={!label}
                error={!value && error}
              >
                {label}
              </Label>
            )}
            <Input
              value={value}
              onChange={onChange}
              error={!value && error}
              {...props}
            />
            {isLoading ? (
              <S.Loading>
                <Spinner size="sm" />
              </S.Loading>
            ) : null}
            <S.InputFieldHelperText error={!value && error}>
              {helperText}
            </S.InputFieldHelperText>
          </S.InputFieldContainer>
        )}
      />
    );
  }

  return (
    <S.InputFieldContainer width={width}>
      <Label for={props?.name} hidden={!label}>
        {label}
      </Label>
      <Input {...props} />
      {error && (
        <S.InputFieldHelperText error={!props?.value && error}>
          {helperText}
        </S.InputFieldHelperText>
      )}
    </S.InputFieldContainer>
  );
};

export const TextHiddenField = ({ name, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <input type="hidden" value={value} onChange={onChange} {...props} />
    )}
  />
);

export const MaskField = ({
  name,
  control,
  label,
  required,
  onChange: onChangeProps,
  width,
  mask,
  maskChar,
  value: valueDefault,
  helperText,
  error,
  isLoading,
  ...props
}) => {
  const { skin } = useSkin();

  if (control) {
    return (
      <Controller
        rules={{ required }}
        name={name}
        control={control}
        as={S.InputMaskCustom}
        render={({ field: { onChange, value } }) => {
          return (
            <S.InputFieldContainer width={width} positionRelative={true}>
              <Label>{label}</Label>
              <S.InputMaskCustom
                mask={mask}
                maskChar={maskChar}
                value={value}
                onChange={e => {
                  const { value } = e.target;
                  onChange(value);
                  if (onChangeProps) {
                    onChangeProps(value);
                  }
                }}
                skin={skin}
              >
                {inputProps => (
                  <S.InputField
                    {...props}
                    {...inputProps}
                    type="text"
                    disableUnderline
                  />
                )}
              </S.InputMaskCustom>
              {isLoading ? (
                <S.Loading>
                  <Spinner size="sm" />
                </S.Loading>
              ) : null}
              <S.InputFieldHelperText error={!value && error}>
                {helperText}
              </S.InputFieldHelperText>
            </S.InputFieldContainer>
          );
        }}
      />
    );
  }

  return (
    <S.InputFieldContainer width={width}>
      <S.InputFieldLabel>{label}</S.InputFieldLabel>
      <S.InputMaskCustom
        skin={skin}
        mask={mask}
        value={valueDefault}
        onChange={onChangeProps}
      >
        {inputProps => (
          <S.InputField
            {...props}
            {...inputProps}
            type="text"
            disableUnderline
          />
        )}
      </S.InputMaskCustom>
      <S.InputFieldHelperText error={!valueDefault && error}>
        {helperText}
      </S.InputFieldHelperText>
    </S.InputFieldContainer>
  );
};

export const CurrencyField = ({
  name,
  label,
  prefix = "R$",
  control,
  error,
  required,
  helperText,
  onChange: onChangeProps,
  ...props
}) => {
  if (control) {
    return (
      <Controller
        rules={{ required }}
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <S.CurrencyField>
            <Label>{label}</Label>
            <CurrencyInput
              value={value}
              placeholder={`${prefix} 0,00`}
              prefix={prefix}
              decimalsLimit={2}
              onValueChange={value => {
                onChange(value);
              }}
              onChange={e => {
                const { value } = e.target;
                if (onChangeProps) {
                  onChangeProps(value);
                }
              }}
              {...props}
            />
            <S.InputFieldHelperText error={!value && error}>
              {helperText}
            </S.InputFieldHelperText>
          </S.CurrencyField>
        )}
      />
    );
  }

  return (
    <S.CurrencyField>
      <Label>{label}</Label>
      <CurrencyInput
        placeholder={`${prefix} 0,00`}
        // placeholder="R$"
        prefix={prefix}
        decimalsLimit={2}
        onValueChange={value => onChangeProps && onChangeProps(value)}
        {...props}
      />
    </S.CurrencyField>
  );
};
