import { useMemo } from "react";
import { UncontrolledTooltip } from "reactstrap";

import NewBadge from "components/structure/Badge/newBadge";
import { limitCaracter } from "utils";

export const TooltipInfo = ({
  row,
  fieldKey,
  field,
  color,
  subField = "",
  limit = 40,
  isBadge = false,
}) => {
  const idTooltip = `˜${row[fieldKey]?.toString()}-tooltip-${field}-${
    isBadge ? "badge" : ""
  }`;
  const value = useMemo(() => {
    if (subField && row[field] && row[field][subField] !== undefined) {
      return row[field][subField] || "";
    }

    return row[field] || "";
  }, [field, subField, row]);

  return (
    <>
      {!isBadge ? (
        <p className="m-0" id={idTooltip}>
          {value ? limitCaracter(limit, value) : "---"}
        </p>
      ) : (
        <NewBadge
          isCopy
          pill
          color={color}
          id={idTooltip}
          value={value ? limitCaracter(limit, value) : "---"}
        />
      )}

      <UncontrolledTooltip placement="top" target={idTooltip}>
        {value ? value : "Não informado'"}
      </UncontrolledTooltip>
    </>
  );
};
