import { createSlice } from "@reduxjs/toolkit";

export const inicialChecklist = {
  params: {
    limit: 10,
    page: 1,
  },
  filter: {},
  modificador: {},
  refresh: null,
};

const initialState = () => inicialChecklist;

export const ChecklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleFilterQuest(state, { payload }) {
      state.params.quest_pergunta = payload;
    },
    handleFilterUF(state, { payload }) {
      state.params.quest_estado = payload;
    },
    handleFilterModifier(state, { payload }) {
      state.params.mod_sigla = payload;
    },
    handleFilterParams(state) {
      delete state.params.quest_pergunta;
      delete state.params.quest_estado;
      delete state.params.mod_sigla;
      return state;
    },
    handleDeleteFilterQuestion(state) {
      delete state.params.quest_pergunta;
      return state;
    },
    handleModificador(state, { payload }) {
      state.modificador.modificador = payload;
    },
    handleRefresh(state, { payload }) {
      state.refresh = payload;
    },
  },
});

export const {
  handleParams,
  handleFilterQuest,
  handleFilterUF,
  handleFilterModifier,
  handleFilterParams,
  handleDeleteFilterQuestion,
  handleModificador,
  handleRefresh,
} = ChecklistSlice.actions;

export default ChecklistSlice.reducer;
