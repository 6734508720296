import { createSlice } from "@reduxjs/toolkit";

export const initialUsers = {
  list: [],
  params: {
    page: 1,
    limit: 10,
    search: "",
    sobrenome: "",
    email: "",
  },
  type: "",
  totalPages: 0,
  useForm: {
    groupsList: [],
  },
  modal: {
    show: false,
  },
};

const initialState = () => initialUsers;

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
      state.totalPages = payload.totalPages;
    },
    handleSetPage: (state, { payload }) => {
      state.params.page = payload.page;
    },
    handleSetLimit: (state, { payload }) => {
      state.params.page = payload.page;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload?.page) {
        state.params.page = payload.page;
      }
      if (payload?.limit) {
        state.params.limit = payload.limit;
      }
    },
    handleSetSearch: (state, { payload }) => {
      state.params.search = payload?.value;
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
    handleAddGroup: (state, { payload }) => {
      state.useForm.groupsList = [
        ...(state.useForm?.groupsList ?? []),
        payload,
      ];
    },
    handleAddGroupList: (state, { payload }) => {
      state.useForm.groupsList = payload?.list;
    },
    handleRemoveItem: (state, { payload }) => {
      const items = [...state.useForm.groupsList];
      const remove = items.filter(item => item?._id !== payload?.id);

      state.useForm.groupsList = [...remove];
    },
    handleShowModal(state, { payload }) {
      state.modal.show = payload;
    },
  },
});

export const {
  handleSetList,
  handleSetPage,
  handleSetLimit,
  handleSetSearch,
  handleParamsLimit,
  handleAddGroupList,
  handleSetPageAndLimit,
  handleAddGroup,
  handleRemoveItem,
  handleShowModal,
} = usersSlice.actions;

export default usersSlice.reducer;
