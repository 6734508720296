export const TITLE = "Lojas";
export const ACTIONS = "Ações";
export const STORE = "Loja";
export const CNPJ = "CNPJ";
export const CITY = "Cidade";
export const SHOW_LABEL = "Exibir";
export const STATUS = "Status";
export const BUTTON_DELETE = "Remover Loja";

export const regime = [
  { label: "LUCRO REAL", value: "LR" },
  { label: "LUCRO PRESUMIDO", value: "LP" },
  { label: "SIMPLES NACIONAL", value: "SN" },
  { label: "PESSOA FÍSICA", value: "PF" },
];
export const companytype = [
  { label: "MATRIZ", value: "Matriz" },
  { label: "FILIAL", value: "Filial" },
];

export const StatusType = [
  {
    value: true,
    label: `Sim`,
  },
  {
    value: false,
    label: `Não`,
  },
];

export const REGIMES = {
  LUCRO_REAL: "LUCRO REAL",
  LUCRO_PRESUMIDO: "LUCRO PRESUMIDO",
  SIMPLES_NACIONAL: "SIMPLES NACIONAL",
  PESSOA_FISICA: "PESSOA FÍSICA",
};

export const CERTIFICADO_STATUS = {
  CERTIFICADO_VENCIDO: "CERTIFICADO_VENCIDO",
  CERTIFICADO_AVENCER: "CERTIFICADO_AVENCER",
  CERTIFICADO_VENCENDO_HOJE: "CERTIFICADO_VENCENDO_HOJE",
  CERTIFICADO_ATIVO: "CERTIFICADO_ATIVO",
  CERTIFICADO_SEM_CERTIFICADO: "CERTIFICADO_SEM_CERTIFICADO",
  CERTIFICADO_EXPIRADO: "CERTIFICADO_EXPIRADO",
};
