import { createSlice } from "@reduxjs/toolkit";

export const inicialImpactados = {
  params: {
    toggle: false,
  },
};

const initialState = () => {
  return inicialImpactados;
};

export const ImpactadosSlice = createSlice({
  name: "produtosImpactados",
  initialState,
  reducers: {
    handeFilterToggle(state, { payload }) {
      state.params.toggle = payload;
      return state;
    },
  },
});

export const { handeFilterToggle } = ImpactadosSlice.actions;

export default ImpactadosSlice.reducer;
