import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showProdModal: {
    show: false,
    value: null,
  },
};

export const relatorioProdutosSlice = createSlice({
  name: "relatorioProdutos",
  initialState,
  reducers: {
    handleShowHistoricProdModal(state, { payload }) {
      state.showProdModal.show = payload?.show;
      state.showProdModal.value = payload?.value;
    },
    handleOpenDetailProduct: (state, { payload }) => {
      state.detailProduct.cmf_cli_id = payload?.cmf_cli_id;
      state.detailProduct.pcn_cod_interno = payload?.cmf_cli_id;
    },
  },
});

export const { handleShowHistoricProdModal, handleOpenDetailProduct } =
  relatorioProdutosSlice.actions;
export default relatorioProdutosSlice.reducer;
