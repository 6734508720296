import { createSlice } from "@reduxjs/toolkit";

export const inicialPortaria = {
  params: {
    limit: 10,
    page: 1,
    active: false,
  },
  modal: {
    produtos: false,
  },
};

const initialState = () => {
  return inicialPortaria;
};

export const PortariaSlice = createSlice({
  name: "portaria",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      if (payload?.isDelete) {
        delete state.params[payload?.field];
      } else {
        state.params[payload?.field] = payload?.value;
      }
    },
    handleModal: (state, { payload }) => {
      const field = payload?.field;
      state.modal[field] = payload?.value;
      return state;
    },
  },
});

export const { handleParams, handleModal } = PortariaSlice.actions;

export default PortariaSlice.reducer;
