import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  params: {
    page: 1,
    limit: 10,
  },
  modal: {
    show: false,
  },
  filter: [],
  modalSelectProd: {
    show: false,
  },
};

const reducers = {
  handleOpenModal: (state, { payload }) => {
    state.modal = payload;
  },

  handleChangeFilters: (state, { payload }) => {
    const copyFilters = payload?.filters ?? [];
    state.filter = copyFilters?.map(item => item);
    return state;
  },
  handleParams: (state, { payload }) => {
    const field = payload?.field;
    state.params[field] = payload?.value;
    return state;
  },
  handleOpenModalSelectProd: (state, { payload }) => {
    state.modalSelectProd = payload;
  },
};

const InvoiceReceiptSlice = createSlice({
  name: "invoiceReceipt",
  initialState: INITIAL_STATE,
  reducers,
});

const reducer = InvoiceReceiptSlice.reducer;
export const actions = InvoiceReceiptSlice.actions;
export default reducer;
