import { useFetchMixAPi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useProdutosByEsp = id =>
  useFetchMixAPi1(id ? `/legislacao/produtos/esp/${id}` : null);

export const usePortariaDetail = id =>
  useFetchMixAPi1(id ? `/legislacao/portaria/detail/${id}` : null);

export const usePorEspValores = (id, params = {}) =>
  useFetchMixAPi1(
    id
      ? getURLWithParams(
          `/legislacao/especificacaoPortaria/listbyportaria/${id}`,
          params,
        )
      : null,
  );

export const usePortariaToast = (id = null) => {
  const { onSend } = useMixApi1Toast();
  const onCreate = async data => {
    const endpoint = id
      ? `legislacao/portaria/update/${id}`
      : `legislacao/portaria/create`;
    const type = id ? "PUT" : "POST";

    onSend({
      endpoint,
      messageSuccess: id
        ? `Edição realizada com Sucesso`
        : `Portaria Cadastrada com Sucesso`,
      messageFail: id
        ? `Não foi possível realizar edição`
        : `Não foi possível cadastrar a Portaria`,
      data,
      type,
      redirect: id ? false : true,
      fieldGetId: "mxf_por_id",
      urlRedirect: "/portaria/editar",
    });
  };

  const onRemove = () => {
    const endpoint = `legislacao/portaria/delete/${id}`;
    const type = "DELETE";

    onSend({
      endpoint,
      messageSuccess: `Portaria Removida com Sucesso`,
      messageFail: `Não foi possível remover a Portaria`,
      type,
    });
  };
  return {
    onCreate,
    onRemove,
  };
};
