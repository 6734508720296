import { createSlice } from "@reduxjs/toolkit";

export const initialTaxLogs = {
  params: {
    limit: 10,
    page: 1,
  },
  filter: {},
  show: true,
  selectedRows: [],
};

const initialState = () => initialTaxLogs;

export const taxLogsSlice = createSlice({
  name: "taxLogs",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleSendFilter: (state, { payload }) => {
      state.filter = payload;
    },
    handleShowExpandable: (state, { payload }) => {
      state.show = payload;
    },
    handleSelectRow: (state, { payload }) => {
      const rowSelected = [...state.selectedRows];

      const checkExists = rowSelected?.filter(
        item => item?.log_id === payload?.log_id,
      );
      if (checkExists?.length) {
        state.selectedRows = rowSelected?.filter(
          item => item?.log_id !== payload?.log_id,
        );
      } else {
        const updateSelecteds = rowSelected.map(item => {
          return {
            ...item,
          };
        });
        state.selectedRows = [...updateSelecteds, payload];
      }
    },
  },
});

export const {
  handleParams,
  handleSendFilter,
  handleShowExpandable,
  handleSelectRow,
} = taxLogsSlice.actions;

export default taxLogsSlice.reducer;
