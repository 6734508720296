import { USER_TYPE } from "constants/enums";

export const getModulesOnList = modules => {
  try {
    const modulesList = [];

    for (const module of modules) {
      if (module?.childrens?.length) {
        for (const moduleChildren of module.childrens) {
          modulesList.push({ ...moduleChildren, parent: module });
        }
      }
      modulesList.push(module);
    }
    return modulesList;
  } catch (error) {
    console.log({ error });
    return [];
  }
};

const getModule = (id, modules) => modules.find(m => m.moduleId === id);

const getModuleParentFields = ({ parent, childrens, rules }) => ({
  moduleId: parent._id,
  createdAt: parent?.createdAt,
  fatherId: parent?.fatherId,
  name: parent?.name,
  slug: parent?.slug,
  updatedAt: parent?.updatedAt,
  childrens,
  rules,
});

// TODO: Retirar esse monstro daqui e colocar dentro de utils/contexts/acl
export const getPermissionsByModules = (modules, groupModules) => {
  const module = {};
  const onlyList = getModulesOnList(modules ?? []);

  for (const item of onlyList) {
    const moduleItem = getModule(item._id, groupModules);
    if (moduleItem) {
      if (item?.parent) {
        const parentItem = getModule(item.parent._id, groupModules);
        if (item?.parent && !module[item?.parent?._id]) {
          module[item.parent._id] = getModuleParentFields({
            parent: item.parent,
            childrens: [{ ...item, ...moduleItem }],
            rules: parentItem?.rules ?? {},
          });
        } else {
          module[item?.parent?._id]?.childrens?.push({
            ...item,
            ...moduleItem,
          });
        }
      } else {
        if (!item.childrens?.length && !module[item._id] && !item.fatherId) {
          module[item._id] = { ...item, moduleId: item._id, ...moduleItem };
        }

        if (
          item.childrens?.length >= 1 &&
          !module[item._id] &&
          !item.fatherId
        ) {
          module[item._id] = {
            ...item,
            moduleId: item._id,
            ...moduleItem,
            childrens: item.childrens.map(children => {
              const childrenItem = groupModules.find(
                m =>
                  m.moduleId === children._id ||
                  m.moduleId === children.moduleId,
              );

              return {
                ...children,
                ...(childrenItem ?? {}),
              };
            }),
          };
        }
      }
    } else if (item?.parent) {
      const parentItem = getModule(item.parent._id, groupModules);
      if (parentItem && !module[item.parent._id]) {
        module[item.parent._id] = getModuleParentFields({
          parent: item.parent,
          childrens: [
            {
              ...item,
              moduleId: item._id,
            },
          ],
          rules: parentItem?.rules ?? {},
        });
      } else {
        module[item?.parent?._id]?.childrens?.push({
          ...item,
          moduleId: item._id,
        });
      }
    }
  }
  return Object.values(module);
};

/**
 * Retorna se o usuario e interno da mix Fiscal
 * @param {Object} userData
 * @param {Boolean} userData.is_mix
 * @param {String} userData.type
 * @returns {Boolean}
 */
export const hasUserInternal = userData =>
  userData?.is_mix ||
  userData?.type === USER_TYPE.ROOT ||
  userData?.type === USER_TYPE.DEV ||
  userData?.type === USER_TYPE.ADMIN;
