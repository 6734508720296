import { createSlice } from "@reduxjs/toolkit";

export const inicialFiscalClassification = {
  classType: "setor",
  classification: true,
  showModal: {
    show: false,
    value: {},
  },
  correct: {
    id: "",
    value: false,
  },
  selectedRows: [],
  selectedExcludeRows: [],
  showIcon: {
    show: false,
    value: "",
  },

  filter: {
    page: 1,
    limit: 10,
    mostrar_pai_sem_filho: false,
    show_efdc: false,
    show_cmf: true,
    uf: { value: "SP", label: "São Paulo" },
    show_clientes: false,
    esp: false,
    show_count_alls: false,
    mxf_set_id: null,
    cli_id: 6029,
    relatorio_tipo: {
      value: "undefined",
      label: "Todos",
    },
    status_classificacao: {
      value: "nao_classificado",
      label: "Não classificados",
    },
  },
  classificationFloat: false,
  showProdModal: {
    show: false,
    value: {},
  },
};

const initialState = () => {
  return inicialFiscalClassification;
};

export const fiscalClassificationSlice = createSlice({
  name: "classificacaoFiscal",
  initialState,
  reducers: {
    handleSetClassType: (state, { payload }) => {
      state.classType = payload;
    },
    handleSetFilter: (state, { payload }) => {
      const copyFilters = { ...state.filter };
      const newFilters = payload?.filters;

      Object.keys(newFilters).map(item => {
        const value = newFilters[item];
        if (value !== undefined) {
          state.filter[item] = value;
        } else {
          if (copyFilters[item] && !value) {
            delete state.filter[item];
          }
        }
      });
    },
    handleShowClassification: (state, { payload }) => {
      state.classification = payload;
    },
    handleShowModal: (state, { payload }) => {
      state.showModal.show = payload.show;
      state.showModal.value = payload.value;
    },
    handleSetCorrect: (state, { payload }) => {
      state.correct.value = payload.value;
      state.correct.id = payload.id;
    },
    handleSelectRow: (state, { payload }) => {
      const rowSelected = [...state.selectedRows];

      const checkExists = rowSelected?.filter(
        item => item?.prod_id === payload?.prod_id,
      );

      if (checkExists?.length) {
        state.selectedRows = rowSelected?.filter(
          item => item?.prod_id !== payload?.prod_id,
        );
      } else {
        state.selectedRows = [...rowSelected, payload];
      }
    },
    handleCleanSelecteds: (state, { payload }) => {
      state.selectedExcludeRows = [
        ...(state.selectedExcludeRows ?? []),
        ...(state.selectedRows ?? []),
        ...(payload ?? []),
      ];

      state.selectedRows = [];
    },
    handleShowIcons: (state, { payload }) => {
      state.showIcon.show = payload?.show;
      state.showIcon.value = payload?.value;
    },
    handleParams: (state, { payload }) => {
      const field = payload?.field;

      state.filter[field] = payload?.value;
    },

    handleClassificationFloat(state, { payload }) {
      state.classificationFloat = payload;
      return state;
    },
    handleCleanPages(state) {
      state.filter.page = 1;
    },
    handleShowProdModal(state, { payload }) {
      state.showProdModal.show = payload?.show;
      state.showProdModal.value = payload?.value;
    },
  },
});

export const {
  handleSetClassType,
  handleShowClassification,
  handleShowModal,
  handleSetCorrect,
  handleSelectRow,
  handleShowIcons,
  handleSetFilter,
  handleParams,
  handleClassificationFloat,
  handleShowProdModal,
  handleCleanPages,
  handleCleanSelecteds,
} = fiscalClassificationSlice.actions;

export default fiscalClassificationSlice.reducer;
