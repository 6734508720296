import { createSlice } from "@reduxjs/toolkit";

export const inicialEspecificacao = {
  params: {
    limit: 15,
    page: 1,
  },
  update: false,
};

const initialState = () => {
  return inicialEspecificacao;
};

export const CasosSlice = createSlice({
  name: "especificacoes",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleUpdate: (state, { payload }) => {
      state.update = payload;
      return state;
    },
    handleSetSearch: (state, { payload }) => {
      const field = payload?.field;
      if (payload?.isDelete) {
        delete state.params[payload?.field];
      } else {
        state.params[field] = payload?.value;
      }
      return state;
    },
  },
});

export const { handleParams, handleUpdate, handleSetSearch } =
  CasosSlice.actions;

export default CasosSlice.reducer;
